import 'rc-banner-anim/assets/index.css';
import React from 'react';
import QueueAnim from 'rc-queue-anim';
import BannerAnim from 'rc-banner-anim';
import { Button, Row, Col } from 'antd';
import { banner } from './data';


const { Element } = BannerAnim;
const { BgElement } = Element;

class Banner extends React.PureComponent {
  getDuration = (e) => {
    if (e.key === 'map') {
      return 800;
    }
    return 1000;
  };
  render() {
    const { isMobile } = this.props;
    const bannerChildren = banner.map((item, i) => {
      const children = item.children.map((child, ii) => {
        const tag = child.tag === 'button' ? 'div' : child.tag || 'p';
        const childrenToRender = child.tag === 'button' ?
          <Button><a href={child.link} target="_blank">{child.children}</a></Button> :
          child.children;
        return React.createElement(tag, {
          key: ii.toString(),
          className: child.className,
          style: child.style || {},
        }, childrenToRender);
      });
      return (
        <Element key={i.toString()}>
          <BgElement
            key="bg"
            className="banner-bg"
            style={{ backgroundImage: `url(${isMobile ? item.imgMobile : item.img})` }}
          />
          <QueueAnim
            key="text"
            className={item.className}
            ease={['easeOutCubic', 'easeInQuad']}
            type={item.queueAnim || 'bottom'}
          >
            {children}
          </QueueAnim>
        </Element >);
    });
    return (
      <div className="banner page-wrapper" style={{ width: "100%" }} >
        <div className="page">
            <img src="/logo.png" alt="image" style={{  width: "190px", height:"80px", margin: "86px auto 40px auto", marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />
          <BannerAnim type="across" duration={550} ease="easeInOutQuint" >
            <Element
              key="text"
              ease={['easeOutCubic', 'easeInQuad']}
              type={ 'bottom'}
            >
              <div style={{ padding: "50px" }}>
                <Row>
                  <Col span={10}>
                      <img src="/tdfck/TDF2-image.png" alt="image" style={{width: "80%"}} />
                  </Col>
                  <Col span={14}>
                    <b>
                      <span style={{backgroundColor:"RGB(229,0,13)", color: "white", padding: "5px", marginRight: "5px", fontSize: "20px"}}>TDF2</span>
                      <span style={{color:"RGB(229,0,13)", padding: "5px", marginRight: "5px", fontSize: "20px"}}>Series High Efficiency Three Phase Asynchronous Motor</span>
                    </b>
                    <hr style={{marginTop: "10px",marginBottom: "10px"}}/>
                    <span style={{textAlign: "justify", fontSize: "12px", color: 'black'}}>
                      TDF2 series motor is developed with high efficiency in order to meet the requirements of the national energy emission
                      reduction & conservation and market demand. It has many advantages such as high efficiency, low noise, high torque,
                        good starting performance, compact structure and easy maintenance. It has been widely used in various fields of
                        national economy such as machine tool, compressor, pump, fan industries, mixing, printing, agricultural machinery,
                        food industry and many other industries where the application is not involving any flammable or corrosive gases.
                    </span> <br/> <br/>
                    <span style={{color:"RGB(229,0,13)", fontSize: "16px"}}><b><u>Technical Parameters</u></b></span>
                    <div>
                        <span style={{fontSize: "12px", color: 'black'}}>1. Basic Feature</span>
                      <ul style={{fontSize: "12px", color: 'black'}}>
                        <li>Center Height of Frame Size: 63 – 355mm</li>
                        <li>Power Range: 0.12kW – 315kW</li>
                        <li>Rated Voltage and Frequency: 415V/50Hz</li>
                        <li>Protection Class: IP55</li>
                        <li>Insulation Class: 155(F)</li>
                        <li>Duty Type: S1 (Continuous), Allow Starting at Full Voltage</li>
                        <li>Type of Cooling: IC411</li>
                        <li>Terminal Connection: Star Connection - Motor Power ≤3kW | Delta Connection - Motor Power >3kW</li>
                      </ul>
                    </div>
                    <span style={{color:"RGB(229,0,13)", fontSize: "16px"}}><b><u>Model Code</u></b></span><br/>
                    <img src="/tdfck/TDF2ModelCode.png" alt="image" style={{width: "60%", padding: "10px"}} />
                  </Col>
                </Row>
              </div>
            </Element>
          </BannerAnim>
        </div>
      </div>
    );
  }
}

export default Banner;
