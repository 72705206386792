import React from 'react';
import { Row, Col } from 'antd';
import { footer } from './data';

export default function Footer() {
  return (
    <footer className="footer page-wrapper">

      <div className="footer-bottom">
        <div className="page">
          <Row>
            <Col md={12} xs={24} style={{ textAlign: 'left' }} className="mobile-hide">

              <h3 style={{ color: 'white' }}><u>TDF Link Industrial Sdn Bhd - 2021014407 (1414707-V)</u></h3>
              <span style={{ fontSize: '10px' }}>📍</span> &nbsp;  <span style={{ fontSize: '12px' }}>5111, 1st Floor Jalan Capri, Off jalan Heng Choon Thien, 12000 Butterworth, Pulau Pinang</span><br/>
              <span style={{ fontSize: '10px' }}>☎</span>   <span style={{ fontSize: '12px' }}>+604-313 1210</span><br/>
              <span style={{ fontSize: '10px' }}>✉</span>   <a style={{ fontSize: '12px' }} href="mailto:tdflinkinds@gmail.com">tdflinkinds@gmail.com</a><br/>

            </Col>
            <Col md={12} xs={24}>
              <span style={{ marginRight: 12 }}>Copyright © 2022 TDF Link BHD</span>
            </Col>
          </Row>
        </div>

      </div>
    </footer>);
}
