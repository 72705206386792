import logo from './logo.svg';
import './App.css';
import {   BrowserRouter as Router,
  Routes,
  Route,
useRoutes } from 'react-router-dom';
import { enquireScreen } from 'enquire-js';
import Home from './Home';
import Product from './Product';
import ProductTDF3 from './ProductTDF3';
const Apps = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
      { path: "/Products/TDF2", element: <Product /> },
      { path: "/Products/TDF3", element: <ProductTDF3 /> },
    // ...
  ]);
  return routes;
};

function App() {
  return (
    <Router>
     <Apps />
   </Router>
  );
}

export default App;
