import React from 'react';
import { Table, Row  } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { tdf2_2pole, tdf2_4pole, tdf2_6pole, tdf2_8pole } from './data';
import "./index.css";


function TabPanel(props) {
 const { children, value, index, ...other } = props;
 return (
   <div
     role="tabpanel"
     hidden={value !== index}
     id={`simple-tabpanel-${index}`}
     aria-labelledby={`simple-tab-${index}`}
     {...other}
   >
     {value === index && (
       <Box sx={{ p: 3 }}>
         <Typography>{children}</Typography>
       </Box>
     )}
   </div>
 );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default class Design extends React.PureComponent {
  state = {
    hover: null,
    size: 'small',
    valueSelected: 0,
  };

  render() {
    const { isMobile } = this.props;
     const { size, valueSelected } = this.state;

     const onChangeValue = (event, newValue) => {
       this.setState({
         valueSelected : newValue
       })
     }
     const columns = [
       {
         title: 'Type',
         dataIndex: 'type',
         key: 'type',
       },
       {
         title: 'Rated Output',
         children: [
           {
             title: 'kW',
             dataIndex: 'kw',
             key: 'kw',
           },
           {
             title: 'HP',
             dataIndex: 'hp',
             key: 'hp',
           },
         ]
       },
       {
         title: 'Rated Speed rpm',
         dataIndex: 'rpm',
         key: 'rpm',
       },
       {
         title: 'Efficiency Ƞ (%) Load',
         dataIndex: 'load',
         key: 'load',
       },
       {
         title: 'Power factor cos φ',
         dataIndex: 'cos',
         key: 'cos',
       },
       {
         title: 'In (A)',
         dataIndex: 'ina',
         key: 'ina',
       },
       {
         title: 'Is/In',
         dataIndex: 'isin',
         key: 'isin',
       },
       {
         title: 'Ts/Tn',
         dataIndex: 'ts',
         key: 'ts',
       },
       {
         title: 'Tmax/Tn',
         dataIndex: 'tmax',
         key: 'tmax',
       }
     ];
    return (
      <div className="page-wrapper page2">
        <div className="page">
          <h1>TDF2 Series</h1>
          <i />
          <div>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant="scrollable" scrollButtons="auto" value={valueSelected} aria-label="basic tabs example" onChange={onChangeValue}>
                  <Tab label="3000RPM (2 Pole) 50HZ" {...a11yProps(0)} style={{color: valueSelected === 0 ? "white" : "#5BA9F9" }}/>
                  <Tab label="1500RPM (4 Pole) 50HZ" {...a11yProps(1)} style={{color: valueSelected === 1 ? "white" : "#5BA9F9" }} />
                  <Tab label="1000RPM (6 Pole) 50HZ" {...a11yProps(2)} style={{color: valueSelected === 2 ? "white" : "#5BA9F9" }} />
                  <Tab label="750RPM (8 Pole) 50HZ" {...a11yProps(3)} style={{color: valueSelected === 3 ? "white" : "#5BA9F9" }} />
                </Tabs>
              </Box>
              <TabPanel value={valueSelected} index={0}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={tdf2_2pole}
                  pagination={false}
                />
              </TabPanel>
              <TabPanel  value={valueSelected} index={1}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={tdf2_4pole}
                  pagination={false}
                />
              </TabPanel>
              <TabPanel  value={valueSelected} index={2}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={tdf2_6pole}
                  pagination={false}
                />
              </TabPanel>
              <TabPanel  value={valueSelected} index={3}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={tdf2_8pole}
                  pagination={false}
                />
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>);
  }
}
