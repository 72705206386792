import React from 'react';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { page4 } from './data';

function TabPanel(props) {
 const { children, value, index, ...other } = props;
 return (
   <div
     role="tabpanel"
     hidden={value !== index}
     id={`simple-tabpanel-${index}`}
     aria-labelledby={`simple-tab-${index}`}
     {...other}
   >
     {value === index && (
       <Box sx={{ p: 3 }}>
         <Typography>{children}</Typography>
       </Box>
     )}
   </div>
 );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default class Design extends React.PureComponent {
  state = {
    hover: null,
    size: 'small',
    valueSelected: 0,
  };
  render() {
    const { size, valueSelected } = this.state;
    const onChangeValue = (event, newValue) => {
      this.setState({
        valueSelected : newValue
      })
    }
    return (
      <div className="page-wrapper page4">
        <div className="page">
          <h1>Dimension Data TDF2</h1>
          <i />
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant="scrollable" scrollButtons="auto" value={valueSelected} aria-label="scrollable auto tabs example" onChange={onChangeValue}>
                  <Tab label="Foot Mounted (B3) - 63M to 355L" {...a11yProps(0)} style={{color: valueSelected === 0 ? "#1976d2" : "#93c6fb", fontSize: "8px" }}/>
                  <Tab label="Flange Mounted (B5) - 63M to 280M" {...a11yProps(1)} style={{color: valueSelected === 1 ? "#1976d2" : "#93c6fb", fontSize: "8px" }} />
                  <Tab label="Foot + Flange Mounted (B35) - 63M to 355L" {...a11yProps(2)} style={{color: valueSelected === 2 ? "#1976d2" : "#93c6fb", fontSize: "8px" }} />
                  <Tab label="Flange Mounted (B14) -  63M to 112M" {...a11yProps(3)} style={{color: valueSelected === 3 ? "#1976d2" : "#93c6fb", fontSize: "8px" }} />
                  <Tab label="Foot + Flange Mounted (B34) - 63M to 112M" {...a11yProps(4)} style={{color: valueSelected === 4 ? "#1976d2" : "#93c6fb", fontSize: "8px" }} />
                  <Tab label="Flange Mounted (V1) - 180M to 355L" {...a11yProps(5)} style={{color: valueSelected === 5 ? "#1976d2" : "#93c6fb", fontSize: "8px" }} />
                </Tabs>
              </Box>
              <TabPanel value={valueSelected} index={0}>
                <img src="/tdfck/tdf-263m-355l.png" alt="image" style={{ maxWidth: "600px", width: "100%", marginLeft: 'auto', marginRight: 'auto', display: 'block'  }} />
              </TabPanel>
              <TabPanel  value={valueSelected} index={1}>
                <img src="/tdfck/tdf2-63-280.png" alt="image" style={{ maxWidth: "600px", width: "100%", marginLeft: 'auto', marginRight: 'auto', display: 'block'  }} />
              </TabPanel>
              <TabPanel  value={valueSelected} index={2}>
                <img src="/tdfck/tdf2-63-355.png" alt="image" style={{ maxWidth: "600px", width: "100%", marginLeft: 'auto', marginRight: 'auto', display: 'block'  }} />
              </TabPanel>
              <TabPanel  value={valueSelected} index={3}>
                <img src="/tdfck/tdf2-63-112.png" alt="image" style={{ maxWidth: "600px", width: "100%", marginLeft: 'auto', marginRight: 'auto', display: 'block'  }} />
              </TabPanel>
              <TabPanel  value={valueSelected} index={4}>
                <img src="/tdfck/tdf2-63-112-b34.png" alt="image" style={{ maxWidth: "600px", width: "100%", marginLeft: 'auto', marginRight: 'auto', display: 'block'  }} />
              </TabPanel>
              <TabPanel  value={valueSelected} index={5}>
                <img src="/tdfck/tdf2-180-355.png" alt="image" style={{ maxWidth: "600px", width: "100%", marginLeft: 'auto', marginRight: 'auto', display: 'block'  }} />
              </TabPanel>
            </Box>
        </div>
      </div>
    );
  }
}
