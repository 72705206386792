export const header = [
  {
    title: '产品',
    children: [
      {
        title: '云凤蝶', desc: '移动建站平台', img: 'https://gw.alipayobjects.com/zos/rmsportal/fLPzRmwAurHkPDVfHHiQ.svg', link: 'https://fengdie.alipay-eco.com/intro', top: '2px',
      },
    ],
  },
  {
    title: '设计体系',
    children: [
      {
        title: '设计价值观', desc: 'Design Values', img: 'https://gw.alipayobjects.com/zos/rmsportal/zMeJnhxAtpXPZAUhUKJH.svg', link: 'https://ant.design/docs/spec/values-cn',
      },
      {
        title: '视觉', desc: 'Visual', img: 'https://gw.alipayobjects.com/zos/rmsportal/qkNZxQRDqvFJscXVDmKp.svg', link: 'https://ant.design/docs/spec/colors-cn',
      },
      {
        title: '可视化', desc: 'Visualisation', img: 'https://gw.alipayobjects.com/zos/rmsportal/MrUQjZNOJhYJCSZZuJDr.svg', link: 'https://antv.alipay.com/zh-cn/vis/index.html',
      },
    ],
  },
  {
    title: '技术方案',
    children: [
      {
        title: 'Ant Design', desc: '蚂蚁 UI 体系', img: 'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg', link: 'https://ant.design',
      },
      {
        title: 'AntV', desc: '蚂蚁数据可视化解决方案', img: 'https://gw.alipayobjects.com/zos/rmsportal/crqUoMinEgjMeGGFAKzG.svg', link: 'https://antv.alipay.com',
      },
      {
        title: 'Egg', desc: '企业级 Node 开发框架', img: 'https://gw.alipayobjects.com/zos/rmsportal/nEEwwpmNVihZimnBAtMf.svg', link: 'https://eggjs.org',
      },
    ],
  },
  {
    title: '关于',
    children: [
      {
        title: '蚂蚁金服体验科技专栏', desc: '探索极致用户体验与最佳工程实践', img: 'https://gw.alipayobjects.com/zos/rmsportal/VsVqfjYxPTJaFbPcZqMb.svg', link: 'https://zhuanlan.zhihu.com/xtech',
      },
    ],
  },
];
export const banner = [
  {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/cTyLQiaRrpzxFAuWwoDQ.svg',
    imgMobile: 'https://gw.alipayobjects.com/zos/rmsportal/ksMYqrCyhwQNdBKReFIU.svg',
    className: 'seeconf-wrap',
    children: [
      { children: 'Seeking Experience & Engineering Conference', className: 'seeconf-en-name' },
      { children: '首届蚂蚁体验科技大会', className: 'seeconf-title', tag: 'h1' },
      { children: '探索极致用户体验与最佳工程实践', className: 'seeconf-cn-name' },
      {
        children: '了解详细',
        className: 'banner-button',
        tag: 'button',
        link: 'https://seeconf.alipay.com/',
      },
      { children: '2018.01.06 / 中国·杭州', className: 'seeconf-time' },
    ],
  },
  {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/cTyLQiaRrpzxFAuWwoDQ.svg',
    imgMobile: 'https://gw.alipayobjects.com/zos/rmsportal/ksMYqrCyhwQNdBKReFIU.svg',
    className: 'seeconf-wrap',
    children: [
      { children: 'Seeking Experience & Engineering Conference', className: 'seeconf-en-name' },
      { children: '首届蚂蚁体验科技大会', className: 'seeconf-title', tag: 'h1' },
      { children: '探索极致用户体验与最佳工程实践', className: 'seeconf-cn-name' },
      {
        children: '了解详细',
        className: 'banner-button',
        tag: 'button',
        link: 'https://seeconf.alipay.com/',
      },
      { children: '2018.01.06 / 中国·杭州', className: 'seeconf-time' },
    ],
  },
];
export const page1 = {
  title: '自然好用的设计',
  children: [
    {
      title: '设计价值观',
      content: 'Design Values',
      src: 'https://gw.alipayobjects.com/zos/rmsportal/KtRzkMmxBuWCVjPbBgRY.svg',
      color: '#EB2F96',
      shadowColor: 'rgba(166, 55, 112, 0.08)',
      link: 'https://ant.design/docs/spec/values-cn',
    },
    {
      title: '视觉',
      content: 'Visual',
      src: 'https://gw.alipayobjects.com/zos/rmsportal/qIcZMXoztWjrnxzCNTHv.svg',
      color: '#1890FF',
      shadowColor: 'rgba(15, 93, 166, 0.08)',
      link: 'https://ant.design/docs/spec/colors-cn',
    },
    {
      title: '可视化',
      content: 'Visualisation',
      src: 'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
      color: '#AB33F7',
      shadowColor: 'rgba(112, 73, 166, 0.08)',
      link: 'https://antv.alipay.com/zh-cn/vis/index.html',
    },
  ],
};

export const page3 = {
  title: '大家都喜爱的产品',
  children: [
    {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/iVOzVyhyQkQDhRsuyBXC.svg',
      imgMobile: 'https://gw.alipayobjects.com/zos/rmsportal/HxEfljPlykWElfhidpxR.svg',
      src: 'https://gw.alipayobjects.com/os/rmsportal/gCFHQneMNZMMYEdlHxqK.mp4',
    },
    {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/iVOzVyhyQkQDhRsuyBXC.svg',
      imgMobile: 'https://gw.alipayobjects.com/zos/rmsportal/HxEfljPlykWElfhidpxR.svg',
      src: 'https://gw.alipayobjects.com/os/rmsportal/gCFHQneMNZMMYEdlHxqK.mp4',
    },
  ],
};

export const page4 = {
  title: '众多企业正在使用',
  children: [
    'https://gw.alipayobjects.com/zos/rmsportal/qImQXNUdQgqAKpPgzxyK.svg', // 阿里巴巴
    'https://gw.alipayobjects.com/zos/rmsportal/LqRoouplkwgeOVjFBIRp.svg', // 蚂蚁金服
    'https://gw.alipayobjects.com/zos/rmsportal/TLCyoAagnCGXUlbsMTWq.svg', // 人民网
    'https://gw.alipayobjects.com/zos/rmsportal/HmCGMKcJQMwfPLNCIhOH.svg', // cisco
    'https://gw.alipayobjects.com/zos/rmsportal/aqldfFDDqRVFRxqLUZOk.svg', // GrowingIO
    'https://gw.alipayobjects.com/zos/rmsportal/rqNeEFCGFuwiDKHaVaPp.svg', // 饿了么
    'https://gw.alipayobjects.com/zos/rmsportal/FdborlfwBxkWIqKbgRtq.svg', // 滴滴出行
    'https://gw.alipayobjects.com/zos/rmsportal/coPmiBkAGVTuTNFVRUcg.png', // 飞凡网
  ],
};

export const footer = [
  {
    title: '蚂蚁科技',
    children: [
      { title: '蚂蚁金服开放平台', link: 'https://open.alipay.com' },
      { title: '蚂蚁体验云', link: 'https://xcloud.alipay.com' },
      { title: '蚂蚁金融云', link: 'https://www.cloud.alipay.com' },
    ],
  },
  {
    title: '相关会议',
    children: [
      { title: 'ATEC', link: 'https://atec.antfin.com' },
      { title: 'SEE Conf', link: 'https://seeconf.alipay.com' },
    ],
  },
  {
    title: '联系我们',
    children: [
      { title: '蚂蚁金服体验科技专栏', link: 'https://zhuanlan.zhihu.com/xtech' },
      { title: '蚂蚁金服体验科技官微', link: 'https://weibo.com/p/1005056420205486' },
      { title: 'AntV 官微', link: 'https://weibo.com/antv2017' },
      { title: 'Ant Design 专栏', link: 'https://zhuanlan.zhihu.com/antdesign' },
    ],
  },
  {
    title: '蚂蚁体验云',
    icon: 'https://gw.alipayobjects.com/zos/rmsportal/wdarlDDcdCaVoCprCRwB.svg',
    children: [
      { title: 'Ant Design', desc: '蚂蚁 UI 体系', link: 'https://ant.design' },
      { title: 'AntV', desc: '蚂蚁数据可视化方案', link: 'https://antv.alipay.com' },
      // { title: 'AntG', desc: '蚂蚁互动图形技术', link: 'http://antg.alipay.net' },
      { title: 'Egg', desc: '企业级 Node Web 开发框架', link: 'https://eggjs.org' },
      { title: '云凤蝶', desc: '移动建站平台', link: 'https://fengdie.alipay-eco.com/intro' },
    ],
  },
];

export const tdf_2pole = [
        {
            "type": "TDF3-80M1-2",
            "kw": "0.75",
            "hp": "1.0",
            "rpm": "2830",
            "load": "80.7",
            "cos": "0.82",
            "ina": "1.72",
            "isin": "7.0",
            "ts": "2.3",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-80M2-2",
            "kw": "1.1",
            "hp": "1.5",
            "rpm": "2830",
            "load": "82.7",
            "cos": "0.83",
            "ina": "2.43",
            "isin": "7.3",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-90S-2",
            "kw": "1.5",
            "hp": "2.0",
            "rpm": "2840",
            "load": "84.2",
            "cos": "0.84",
            "ina": "3.22",
            "isin": "7.6",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-90L-2",
            "kw": "2.2",
            "hp": "3.0",
            "rpm": "2840",
            "load": "85.9",
            "cos": "0.85",
            "ina": "4.58",
            "isin": "7.6",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-100L-2",
            "kw": "3.0",
            "hp": "4.0",
            "rpm": "2870",
            "load": "87.1",
            "cos": "0.87",
            "ina": "6.02",
            "isin": "7.8",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-112M-2",
            "kw": "4.0",
            "hp": "5.5",
            "rpm": "2890",
            "load": "88.1",
            "cos": "0.88",
            "ina": "7.84",
            "isin": "8.3",
            "ts": "2.3",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-132S1-2",
            "kw": "5.5",
            "hp": "7.5",
            "rpm": "2900",
            "load": "89.2",
            "cos": "0.88",
            "ina": "10.6",
            "isin": "8.3",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-132S2-2",
            "kw": "7.5",
            "hp": "10",
            "rpm": "2900",
            "load": "90.1",
            "cos": "0.88",
            "ina": "14.4",
            "isin": "7.9",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-160M1-2",
            "kw": "11",
            "hp": "15",
            "rpm": "2930",
            "load": "91.2",
            "cos": "0.89",
            "ina": "20.6",
            "isin": "8.1",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-160M2-2",
            "kw": "15",
            "hp": "20",
            "rpm": "2930",
            "load": "91.9",
            "cos": "0.89",
            "ina": "27.9",
            "isin": "8.1",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-160L-2",
            "kw": "18.5",
            "hp": "25",
            "rpm": "2930",
            "load": "92.4",
            "cos": "0.89",
            "ina": "34.2",
            "isin": "8.2",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-180M-2",
            "kw": "22",
            "hp": "30",
            "rpm": "2940",
            "load": "92.7",
            "cos": "0.89",
            "ina": "40.5",
            "isin": "8.2",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-200L1-2",
            "kw": "30",
            "hp": "40",
            "rpm": "2950",
            "load": "93.3",
            "cos": "0.89",
            "ina": "54.9",
            "isin": "7.6",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-200L2-2",
            "kw": "37",
            "hp": "50",
            "rpm": "2950",
            "load": "93.7",
            "cos": "0.89",
            "ina": "67.4",
            "isin": "7.6",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-225M-2",
            "kw": "45",
            "hp": "60",
            "rpm": "2970",
            "load": "94",
            "cos": "0.9",
            "ina": "80.8",
            "isin": "7.7",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-250M-2",
            "kw": "55",
            "hp": "75",
            "rpm": "2970",
            "load": "94.3",
            "cos": "0.9",
            "ina": "98.5",
            "isin": "7.7",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-280S-2",
            "kw": "75",
            "hp": "100",
            "rpm": "2970",
            "load": "94.7",
            "cos": "0.9",
            "ina": "133.7",
            "isin": "7.1",
            "ts": "1.8",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-280M-2",
            "kw": "90",
            "hp": "125",
            "rpm": "2970",
            "load": "95",
            "cos": "0.9",
            "ina": "159.9",
            "isin": "7.1",
            "ts": "1.8",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-315S-2",
            "kw": "110",
            "hp": "150",
            "rpm": "2970",
            "load": "95.2",
            "cos": "0.9",
            "ina": "195.1",
            "isin": "7.1",
            "ts": "1.8",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-315M-2",
            "kw": "132",
            "hp": "175",
            "rpm": "2980",
            "load": "95.4",
            "cos": "0.9",
            "ina": "233.6",
            "isin": "7.1",
            "ts": "1.8",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-315L1-2",
            "kw": "160",
            "hp": "215",
            "rpm": "2980",
            "load": "95.6",
            "cos": "0.91",
            "ina": "279.4",
            "isin": "7.1",
            "ts": "1.8",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-315L2-2",
            "kw": "200",
            "hp": "270",
            "rpm": "2980",
            "load": "95.8",
            "cos": "0.91",
            "ina": "348.6",
            "isin": "7.1",
            "ts": "1.8",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-355M-2",
            "kw": "250",
            "hp": "335",
            "rpm": "2980",
            "load": "95.8",
            "cos": "0.91",
            "ina": "435.7",
            "isin": "7.2",
            "ts": "1.6",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-355L-2",
            "kw": "315",
            "hp": "420",
            "rpm": "2980",
            "load": "95.8",
            "cos": "0.91",
            "ina": "549",
            "isin": "7.2",
            "ts": "1.6",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-3551-2",
            "kw": "355",
            "hp": "420",
            "rpm": "2980",
            "load": "95.8",
            "cos": "0.91",
            "ina": "618.7",
            "isin": "7.2",
            "ts": "1.6",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-3552-2",
            "kw": "375",
            "hp": "420",
            "rpm": "2980",
            "load": "95.8",
            "cos": "0.91",
            "ina": "653.6",
            "isin": "7.2",
            "ts": "1.6",
            "tmax": "2.2"
        }
    ];

export const tdf_4pole = [
        {
            "type": "TDF3-80M2-4",
            "kw": "0.75",
            "hp": "1.0",
            "rpm": "1390",
            "load": "82.5",
            "cos": "0.75",
            "ina": "1.84",
            "isin": "6.6",
            "ts": "2.3",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-90S-4",
            "kw": "1.1",
            "hp": "1.5",
            "rpm": "1400",
            "load": "84.1",
            "cos": "0.76",
            "ina": "2.61",
            "isin": "6.8",
            "ts": "2.3",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-90L-4",
            "kw": "1.5",
            "hp": "2.0",
            "rpm": "1400",
            "load": "85.3",
            "cos": "0.77",
            "ina": "3.47",
            "isin": "7.0",
            "ts": "2.3",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-100L1-4",
            "kw": "2.2",
            "hp": "3.0",
            "rpm": "1430",
            "load": "86.7",
            "cos": "0.81",
            "ina": "4.76",
            "isin": "7.6",
            "ts": "2.3",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-100L2-4",
            "kw": "3.0",
            "hp": "4.0",
            "rpm": "1430",
            "load": "87.7",
            "cos": "0.82",
            "ina": "6.34",
            "isin": "7.6",
            "ts": "2.3",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-112M-4",
            "kw": "4.0",
            "hp": "5.5",
            "rpm": "1440",
            "load": "88.6",
            "cos": "0.82",
            "ina": "8.37",
            "isin": "7.8",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-132S-4",
            "kw": "5.5",
            "hp": "7.5",
            "rpm": "1440",
            "load": "89.6",
            "cos": "0.83",
            "ina": "11.2",
            "isin": "7.9",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-132M-4",
            "kw": "7.5",
            "hp": "10",
            "rpm": "1440",
            "load": "90.4",
            "cos": "0.84",
            "ina": "15",
            "isin": "7.5",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-160M-4",
            "kw": "11",
            "hp": "15",
            "rpm": "1460",
            "load": "91.4",
            "cos": "0.85",
            "ina": "21.5",
            "isin": "7.7",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-160L-4",
            "kw": "15",
            "hp": "20",
            "rpm": "1460",
            "load": "92.1",
            "cos": "0.86",
            "ina": "28.8",
            "isin": "7.8",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-180M-4",
            "kw": "18.5",
            "hp": "25",
            "rpm": "1470",
            "load": "92.6",
            "cos": "0.86",
            "ina": "35.3",
            "isin": "7.8",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-180L-4",
            "kw": "22",
            "hp": "30",
            "rpm": "1470",
            "load": "93",
            "cos": "0.86",
            "ina": "41.8",
            "isin": "7.8",
            "ts": "2.2",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-200L-4",
            "kw": "30",
            "hp": "40",
            "rpm": "1470",
            "load": "93.6",
            "cos": "0.86",
            "ina": "56.6",
            "isin": "7.3",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-225S-4",
            "kw": "37",
            "hp": "50",
            "rpm": "1480",
            "load": "93.9",
            "cos": "0.86",
            "ina": "69.6",
            "isin": "7.4",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-225M-4",
            "kw": "45",
            "hp": "60",
            "rpm": "1480",
            "load": "94.2",
            "cos": "0.86",
            "ina": "84.4",
            "isin": "7.4",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-250M-4",
            "kw": "55",
            "hp": "75",
            "rpm": "1480",
            "load": "94.6",
            "cos": "0.86",
            "ina": "102.7",
            "isin": "7.4",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-280S-4",
            "kw": "75",
            "hp": "100",
            "rpm": "1480",
            "load": "95",
            "cos": "0.88",
            "ina": "136.3",
            "isin": "6.7",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-280M-4",
            "kw": "90",
            "hp": "125",
            "rpm": "1490",
            "load": "95.2",
            "cos": "0.88",
            "ina": "163.2",
            "isin": "6.9",
            "ts": "2.0",
            "tmax": "2.3"
        },
        {
            "type": "TDF3-315S-4",
            "kw": "110",
            "hp": "150",
            "rpm": "1490",
            "load": "95.4",
            "cos": "0.89",
            "ina": "196.8",
            "isin": "7.0",
            "ts": "2.0",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-315M-4",
            "kw": "132",
            "hp": "175",
            "rpm": "1490",
            "load": "95.6",
            "cos": "0.89",
            "ina": "235.7",
            "isin": "7.0",
            "ts": "2.0",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-315L1-4",
            "kw": "160",
            "hp": "215",
            "rpm": "1490",
            "load": "95.8",
            "cos": "0.89",
            "ina": "285.1",
            "isin": "7.1",
            "ts": "2.0",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-315L2-4",
            "kw": "200",
            "hp": "270",
            "rpm": "1490",
            "load": "96",
            "cos": "0.9",
            "ina": "351.7",
            "isin": "7.1",
            "ts": "2.0",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-355M-4",
            "kw": "250",
            "hp": "335",
            "rpm": "1485",
            "load": "96",
            "cos": "0.9",
            "ina": "439.6",
            "isin": "7.1",
            "ts": "2.0",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-355L-4",
            "kw": "315",
            "hp": "420",
            "rpm": "1485",
            "load": "96",
            "cos": "0.9",
            "ina": "553.9",
            "isin": "7.1",
            "ts": "2.0",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-3551-4",
            "kw": "355",
            "hp": "420",
            "rpm": "1485",
            "load": "96",
            "cos": "0.88",
            "ina": "638.5",
            "isin": "7.0",
            "ts": "1.7",
            "tmax": "2.2"
        },
        {
            "type": "TDF3-3552-4",
            "kw": "375",
            "hp": "420",
            "rpm": "1485",
            "load": "96",
            "cos": "0.88",
            "ina": "674.4",
            "isin": "7.0",
            "ts": "1.7",
            "tmax": "2.2"
        }
    ];

export const tdf_6pole = [
        {
            "type": "TDF3-90S-6",
            "kw": "0.75",
            "hp": "1.0",
            "rpm": "910",
            "load": "78.9",
            "cos": "0.71",
            "ina": "2.03",
            "isin": "6.0",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-90L-6",
            "kw": "1.1",
            "hp": "1.5",
            "rpm": "910",
            "load": "81",
            "cos": "0.73",
            "ina": "2.83",
            "isin": "6.0",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-100L-6",
            "kw": "1.5",
            "hp": "2.0",
            "rpm": "940",
            "load": "82.5",
            "cos": "0.73",
            "ina": "3.78",
            "isin": "6.5",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-112M-6",
            "kw": "2.2",
            "hp": "3.0",
            "rpm": "940",
            "load": "84.3",
            "cos": "0.74",
            "ina": "5.36",
            "isin": "6.6",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-132S-6",
            "kw": "3.0",
            "hp": "4.0",
            "rpm": "960",
            "load": "85.6",
            "cos": "0.74",
            "ina": "7.2",
            "isin": "6.8",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-132M1-6",
            "kw": "4.0",
            "hp": "5.5",
            "rpm": "960",
            "load": "86.8",
            "cos": "0.74",
            "ina": "9.46",
            "isin": "6.8",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-132M2-6",
            "kw": "5.5",
            "hp": "7.5",
            "rpm": "960",
            "load": "88",
            "cos": "0.75",
            "ina": "12.7",
            "isin": "7.0",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-160M-6",
            "kw": "7.5",
            "hp": "10",
            "rpm": "970",
            "load": "89.1",
            "cos": "0.79",
            "ina": "16.2",
            "isin": "7.0",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-160L-6",
            "kw": "11",
            "hp": "15",
            "rpm": "970",
            "load": "90.3",
            "cos": "0.8",
            "ina": "23.1",
            "isin": "7.2",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-180L-6",
            "kw": "15",
            "hp": "20",
            "rpm": "970",
            "load": "91.2",
            "cos": "0.81",
            "ina": "30.9",
            "isin": "7.3",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-200L1-6",
            "kw": "18.5",
            "hp": "25",
            "rpm": "970",
            "load": "91.7",
            "cos": "0.81",
            "ina": "37.8",
            "isin": "7.3",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-200L2-6",
            "kw": "22",
            "hp": "30",
            "rpm": "970",
            "load": "92.2",
            "cos": "0.81",
            "ina": "44.8",
            "isin": "7.4",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-225M-6",
            "kw": "30",
            "hp": "40",
            "rpm": "980",
            "load": "92.9",
            "cos": "0.83",
            "ina": "59.1",
            "isin": "6.9",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-250M-6",
            "kw": "37",
            "hp": "50",
            "rpm": "980",
            "load": "93.3",
            "cos": "0.84",
            "ina": "71.7",
            "isin": "7.1",
            "ts": "2.0",
            "tmax": "2.1"
        },
        {
            "type": "TDF3-280S-6",
            "kw": "45",
            "hp": "60",
            "rpm": "980",
            "load": "93.7",
            "cos": "0.85",
            "ina": "85.8",
            "isin": "7.3",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-280M-6",
            "kw": "55",
            "hp": "75",
            "rpm": "980",
            "load": "94.1",
            "cos": "0.86",
            "ina": "103.3",
            "isin": "7.3",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-315S-6",
            "kw": "75",
            "hp": "100",
            "rpm": "990",
            "load": "94.6",
            "cos": "0.84",
            "ina": "143.1",
            "isin": "6.6",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-315M-6",
            "kw": "90",
            "hp": "125",
            "rpm": "990",
            "load": "94.9",
            "cos": "0.85",
            "ina": "169.5",
            "isin": "6.7",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-315L1-6",
            "kw": "110",
            "hp": "150",
            "rpm": "990",
            "load": "95.1",
            "cos": "0.85",
            "ina": "206.8",
            "isin": "6.7",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-315L2-6",
            "kw": "132",
            "hp": "175",
            "rpm": "990",
            "load": "95.4",
            "cos": "0.86",
            "ina": "244.5",
            "isin": "6.8",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-355M1-6",
            "kw": "160",
            "hp": "215",
            "rpm": "990",
            "load": "95.6",
            "cos": "0.86",
            "ina": "295.7",
            "isin": "6.8",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-355M2-6",
            "kw": "200",
            "hp": "270",
            "rpm": "990",
            "load": "95.8",
            "cos": "0.87",
            "ina": "365",
            "isin": "6.8",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-355L-6",
            "kw": "250",
            "hp": "335",
            "rpm": "990",
            "load": "95.8",
            "cos": "0.87",
            "ina": "455.7",
            "isin": "6.8",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF3-3552-6",
            "kw": "315",
            "hp": "420",
            "rpm": "990",
            "load": "95.8",
            "cos": "0.86",
            "ina": "580.9",
            "isin": "6.8",
            "ts": "1.8",
            "tmax": "2.0"
        }
    ];

export const tdf_8pole = [
        {
            "type": "TDF2-80M1-8",
            "kw": "0.18",
            "hp": "0.25",
            "rpm": "640",
            "load": "45.9",
            "cos": "0.61",
            "ina": "1.0",
            "isin": "5.1",
            "ts": "1.8",
            "tmax": "1.9"
        },
        {
            "type": "TDF2-80M2-8",
            "kw": "0.25",
            "hp": "0.35",
            "rpm": "640",
            "load": "50.6",
            "cos": "0.61",
            "ina": "1.2",
            "isin": "5.5",
            "ts": "1.8",
            "tmax": "1.9"
        },
        {
            "type": "TDF2-90S-8",
            "kw": "0.37",
            "hp": "0.5",
            "rpm": "660",
            "load": "56.1",
            "cos": "0.61",
            "ina": "1.6",
            "isin": "6.0",
            "ts": "1.8",
            "tmax": "1.9"
        },
        {
            "type": "TDF2-90L-8",
            "kw": "0.55",
            "hp": "0.75",
            "rpm": "660",
            "load": "61.7",
            "cos": "0.61",
            "ina": "2.2",
            "isin": "5.8",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-100L1-8",
            "kw": "0.75",
            "hp": "1.0",
            "rpm": "690",
            "load": "66.2",
            "cos": "0.67",
            "ina": "2.6",
            "isin": "6.1",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-100L2-8",
            "kw": "1.1",
            "hp": "1.5",
            "rpm": "690",
            "load": "70.8",
            "cos": "0.69",
            "ina": "3.4",
            "isin": "6.1",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-112M-8",
            "kw": "1.5",
            "hp": "2.0",
            "rpm": "690",
            "load": "74.1",
            "cos": "0.7",
            "ina": "4.4",
            "isin": "6.4",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-132S-8",
            "kw": "2.2",
            "hp": "3.0",
            "rpm": "710",
            "load": "77.6",
            "cos": "0.71",
            "ina": "6.1",
            "isin": "6.4",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-132M-8",
            "kw": "3.0",
            "hp": "4.0",
            "rpm": "710",
            "load": "80",
            "cos": "0.73",
            "ina": "7.8",
            "isin": "6.8",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-160M1-8",
            "kw": "4.0",
            "hp": "5.5",
            "rpm": "720",
            "load": "81.9",
            "cos": "0.73",
            "ina": "10.2",
            "isin": "6.8",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-160M2-8",
            "kw": "5.5",
            "hp": "7.5",
            "rpm": "720",
            "load": "83.8",
            "cos": "0.74",
            "ina": "13.5",
            "isin": "6.7",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-160L-8",
            "kw": "7.5",
            "hp": "10",
            "rpm": "720",
            "load": "85.3",
            "cos": "0.75",
            "ina": "17.8",
            "isin": "6.4",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-180L-8",
            "kw": "11",
            "hp": "15",
            "rpm": "730",
            "load": "86.9",
            "cos": "0.75",
            "ina": "25.6",
            "isin": "6.5",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-200L-8",
            "kw": "15",
            "hp": "20",
            "rpm": "730",
            "load": "88",
            "cos": "0.76",
            "ina": "34.1",
            "isin": "6.6",
            "ts": "2.0",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-225S-8",
            "kw": "18.5",
            "hp": "25",
            "rpm": "735",
            "load": "88.6",
            "cos": "0.76",
            "ina": "41.7",
            "isin": "6.6",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-225M-8",
            "kw": "22",
            "hp": "30",
            "rpm": "735",
            "load": "89.1",
            "cos": "0.78",
            "ina": "48.1",
            "isin": "6.9",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-250M-8",
            "kw": "30",
            "hp": "40",
            "rpm": "735",
            "load": "89.8",
            "cos": "0.79",
            "ina": "64.3",
            "isin": "6.5",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-280S-8",
            "kw": "37",
            "hp": "50",
            "rpm": "740",
            "load": "90.3",
            "cos": "0.79",
            "ina": "78.8",
            "isin": "6.5",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-280M-8",
            "kw": "45",
            "hp": "60",
            "rpm": "740",
            "load": "90.7",
            "cos": "0.79",
            "ina": "95.4",
            "isin": "6.5",
            "ts": "1.9",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-315S-8",
            "kw": "55",
            "hp": "75",
            "rpm": "740",
            "load": "91",
            "cos": "0.81",
            "ina": "113.4",
            "isin": "6.7",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-315M-8",
            "kw": "75",
            "hp": "100",
            "rpm": "740",
            "load": "91.6",
            "cos": "0.81",
            "ina": "154",
            "isin": "6.1",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-315L1-8",
            "kw": "90",
            "hp": "125",
            "rpm": "740",
            "load": "91.9",
            "cos": "0.82",
            "ina": "181",
            "isin": "6.2",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-315L2-8",
            "kw": "110",
            "hp": "150",
            "rpm": "740",
            "load": "92.3",
            "cos": "0.82",
            "ina": "221",
            "isin": "6.3",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-355M1-8",
            "kw": "132",
            "hp": "175",
            "rpm": "740",
            "load": "92.6",
            "cos": "0.82",
            "ina": "264",
            "isin": "6.3",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-355M2-8",
            "kw": "160",
            "hp": "215",
            "rpm": "740",
            "load": "93",
            "cos": "0.82",
            "ina": "319",
            "isin": "6.3",
            "ts": "1.8",
            "tmax": "2.0"
        },
        {
            "type": "TDF2-355L-8",
            "kw": "200",
            "hp": "270",
            "rpm": "740",
            "load": "93.5",
            "cos": "0.83",
            "ina": "392",
            "isin": "6.3",
            "ts": "1.8",
            "tmax": "2.0"
        }
    ];
// 图处预加载；
if (typeof document !== 'undefined') {
  const div = document.createElement('div');
  div.style.display = 'none';
  document.body.appendChild(div);
  [
    'https://gw.alipayobjects.com/zos/rmsportal/KtRzkMmxBuWCVjPbBgRY.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/qIcZMXoztWjrnxzCNTHv.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/iVOzVyhyQkQDhRsuyBXC.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/HxEfljPlykWElfhidpxR.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/wdarlDDcdCaVoCprCRwB.svg',
  ].concat(page4.children).forEach((src) => {
    const img = new Image();
    img.src = src;
    div.appendChild(img);
  });
}
