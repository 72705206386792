export const header = [
  {
    title: 'TDF',
    children: [
      {
        title: 'Home', desc: 'Home page', img: '/icon/factory.png', link: '/',
      },
      {
        title: 'About', desc: 'Soon', img: '/icon/factory.png', link: '/',
      },
    ],
  },
  {
    title: 'Industry Product',
    children: [
      {
        title: 'TDF 2', desc: 'Three Phase Motor', img: '/icon/robotic-arm.png', link: '/Products/TDF2',
      },
      {
        title: 'TDF 3', desc: 'Three Phase Motor', img: '/icon/industrial.png', link: '/Products/TDF3',
      },
    ],
  },
  {
    title: 'Agriculture',
    children: [
      {
        title: 'Product', desc: 'Soon', img: '/icon/sprout.png', link: '/',
      },
    ],
  },
];
export const banner = [
  {
    img: '/bannerIn.PNG',
    imgMobile: '/bannerInMobile.png',
    className: 'seeconf-wrap',
    children: [
      { children: 'THREE-PHASE ASYNCHRONOUS MOTOR', className: 'seeconf-en-name' },
      { children: 'TDF SERIES', className: 'seeconf-title', tag: 'h1' },
      { children: 'TOTALLY ENCLOSED FAN-COOLED (TEFC)', className: 'seeconf-cn-name' },
      {
        children: 'Download Catalogue',
        className: 'banner-button',
        tag: 'button',
        link: '/TDF Link Motor Catalogue.pdf',
      },
      { children: 'TDF2 (IE2) HIGH EFFICIENCY/ TDF3 (IE3) PREMIUM EFFICIENCY', className: 'seeconf-time' },
    ],
  },
  {
    img: 'https://gw.alipayobjects.com/zos/rmsportal/cTyLQiaRrpzxFAuWwoDQ.svg',
    imgMobile: 'https://gw.alipayobjects.com/zos/rmsportal/ksMYqrCyhwQNdBKReFIU.svg',
    className: 'seeconf-wrap',
    children: [
      { children: '农业生产项目', className: 'seeconf-en-name' },
      { children: 'Agriculture', className: 'seeconf-title', tag: 'h1' },
      { children: '为人们多创造一份绿色，从而发挥出荒沟变绿地所带来的良好的社会效益', className: 'seeconf-cn-name' },
      {
        children: 'Soon',
        className: 'banner-button',
        tag: 'button',
        link: '/',
      },
      { children: '2022 / 威北柏淡MPKSM', className: 'seeconf-time' },
    ],
  },
];
export const page1 = {
  title: '农业生产项目',
  children: [
    {
      title: '多样性',
      content: '现代化农业设施',
      src: 'https://gw.alipayobjects.com/zos/rmsportal/KtRzkMmxBuWCVjPbBgRY.svg',
      color: '#EB2F96',
      shadowColor: 'rgba(166, 55, 112, 0.08)',
      link: 'https://ant.design/docs/spec/values-cn',
    },
    {
      title: '绿色生态',
      content: '提供真正无公害、新鲜、营养、优质、安全、无药残的农产品',
      src: 'https://gw.alipayobjects.com/zos/rmsportal/qIcZMXoztWjrnxzCNTHv.svg',
      color: '#1890FF',
      shadowColor: 'rgba(15, 93, 166, 0.08)',
      link: 'https://ant.design/docs/spec/colors-cn',
    },
    {
      title: '社会效益',
      content: '改变当地自然环境，做出现代种植治理的标榜企业',
      src: 'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
      color: '#AB33F7',
      shadowColor: 'rgba(112, 73, 166, 0.08)',
      link: 'https://antv.alipay.com/zh-cn/vis/index.html',
    },
  ],
};

export const page3 = {
  title: '大家都喜爱的产品',
  children: [
    {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/iVOzVyhyQkQDhRsuyBXC.svg',
      imgMobile: 'https://gw.alipayobjects.com/zos/rmsportal/HxEfljPlykWElfhidpxR.svg',
      src: 'https://gw.alipayobjects.com/os/rmsportal/gCFHQneMNZMMYEdlHxqK.mp4',
    },
    {
      img: 'https://gw.alipayobjects.com/zos/rmsportal/iVOzVyhyQkQDhRsuyBXC.svg',
      imgMobile: 'https://gw.alipayobjects.com/zos/rmsportal/HxEfljPlykWElfhidpxR.svg',
      src: 'https://gw.alipayobjects.com/os/rmsportal/gCFHQneMNZMMYEdlHxqK.mp4',
    },
  ],
};

export const page4 = {
  title: '众多企业正在使用',
  children: [
    'https://gw.alipayobjects.com/zos/rmsportal/qImQXNUdQgqAKpPgzxyK.svg', // 阿里巴巴
    'https://gw.alipayobjects.com/zos/rmsportal/LqRoouplkwgeOVjFBIRp.svg', // 蚂蚁金服
    'https://gw.alipayobjects.com/zos/rmsportal/TLCyoAagnCGXUlbsMTWq.svg', // 人民网
    'https://gw.alipayobjects.com/zos/rmsportal/HmCGMKcJQMwfPLNCIhOH.svg', // cisco
    'https://gw.alipayobjects.com/zos/rmsportal/aqldfFDDqRVFRxqLUZOk.svg', // GrowingIO
    'https://gw.alipayobjects.com/zos/rmsportal/rqNeEFCGFuwiDKHaVaPp.svg', // 饿了么
    'https://gw.alipayobjects.com/zos/rmsportal/FdborlfwBxkWIqKbgRtq.svg', // 滴滴出行
    'https://gw.alipayobjects.com/zos/rmsportal/coPmiBkAGVTuTNFVRUcg.png', // 飞凡网
  ],
};

export const footer = [
  {
    title: '蚂蚁科技',
    children: [
      { title: '蚂蚁金服开放平台', link: 'https://open.alipay.com' },
      { title: '蚂蚁体验云', link: 'https://xcloud.alipay.com' },
      { title: '蚂蚁金融云', link: 'https://www.cloud.alipay.com' },
    ],
  },
  {
    title: '相关会议',
    children: [
      { title: 'ATEC', link: 'https://atec.antfin.com' },
      { title: 'SEE Conf', link: 'https://seeconf.alipay.com' },
    ],
  },
  {
    title: '联系我们',
    children: [
      { title: '蚂蚁金服体验科技专栏', link: 'https://zhuanlan.zhihu.com/xtech' },
      { title: '蚂蚁金服体验科技官微', link: 'https://weibo.com/p/1005056420205486' },
      { title: 'AntV 官微', link: 'https://weibo.com/antv2017' },
      { title: 'Ant Design 专栏', link: 'https://zhuanlan.zhihu.com/antdesign' },
    ],
  },
  {
    title: '蚂蚁体验云',
    icon: 'https://gw.alipayobjects.com/zos/rmsportal/wdarlDDcdCaVoCprCRwB.svg',
    children: [
      { title: 'Ant Design', desc: '蚂蚁 UI 体系', link: 'https://ant.design' },
      { title: 'AntV', desc: '蚂蚁数据可视化方案', link: 'https://antv.alipay.com' },
      // { title: 'AntG', desc: '蚂蚁互动图形技术', link: 'http://antg.alipay.net' },
      { title: 'Egg', desc: '企业级 Node Web 开发框架', link: 'https://eggjs.org' },
      { title: '云凤蝶', desc: '移动建站平台', link: 'https://fengdie.alipay-eco.com/intro' },
    ],
  },
];

// 图处预加载；
if (typeof document !== 'undefined') {
  const div = document.createElement('div');
  div.style.display = 'none';
  document.body.appendChild(div);
  [
    'https://gw.alipayobjects.com/zos/rmsportal/KtRzkMmxBuWCVjPbBgRY.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/qIcZMXoztWjrnxzCNTHv.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/iVOzVyhyQkQDhRsuyBXC.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/HxEfljPlykWElfhidpxR.svg',
    'https://gw.alipayobjects.com/zos/rmsportal/wdarlDDcdCaVoCprCRwB.svg',
  ].concat(page4.children).forEach((src) => {
    const img = new Image();
    img.src = src;
    div.appendChild(img);
  });
}
